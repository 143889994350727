import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Img from "gatsby-image";
import SEO from "../components/seo";
import styles from "./project.module.css";

export default function Project({ data }) {

    const { markdownRemark } = data;
    const { frontmatter, html } = markdownRemark;
    const image = frontmatter.featured.childImageSharp.fluid;
    const seoImage = frontmatter.featured.childImageSharp.fixed;

    return (
        <Layout>
            <SEO title={frontmatter.title} description={frontmatter.title} image={seoImage} />
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <h1>{frontmatter.title}</h1>
                <div style={{ flex: '1' }}></div>
                { frontmatter.done === true &&
                <span className="status" style={{ width: 'auto', height: '16px', backgroundColor: '#27ae60' }}>DONE</span>
                }
                { frontmatter.done === false &&
                <div>
                    <span className={styles.status} style={{ width: 'auto', height: '16px', backgroundColor: '#e67e22' }}>WORK IN PROGRESS</span>
                    <span className={styles.ministatus} style={{ width: 'auto', height: '16px', backgroundColor: '#e67e22' }}>WIP</span>
                </div>
                }
            </div>
            <div style={{ marginTop: '10px' }}>
                {
                    frontmatter.tech.map((t, i) => {
                    return (
                        <span key={t} className="pill">{t}</span>
                    )
                    })
                }
            </div>
            <div dangerouslySetInnerHTML={{ __html: html }}></div>
            <Img fluid={image} className={styles.featured} />
        </Layout>
    )
}

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
        done
        link
        tech
        featured {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
            fixed(width: 500, height: 500, fit: CONTAIN) {
              src
              width
              height
            }
          }
        }
      }
    }
  }
`